<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Edit Apoteker'">
          <template v-slot:body>
            <div class="row align-items-center">
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/default-profile.svg"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <div class="col-md" v-if="imgLoaded == true">
                <b-form>
                  <!-- Input Name -->
                  <b-form-group
                    id="input-group-name"
                    label="Nama:"
                    label-for="input-name"
                  >
                    <b-form-input
                      id="input-name"
                      v-model="form.name"
                      required
                      placeholder="Nama Lengkap"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ nameError }}</small>
                  </b-form-group>

                  <!-- Input Email -->
                  <b-form-group
                    id="input-group-email"
                    label="Email:"
                    label-for="input-email"
                  >
                    <b-form-input
                      id="input-email"
                      v-model="form.email"
                      required
                      placeholder="Email"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ emailError }}</small>
                  </b-form-group>

                  <!-- Input Phone -->
                  <b-form-group
                    id="input-group-phone"
                    label="Nomor Handphone:"
                    label-for="input-phone"
                  >
                    <b-form-input
                      id="input-phone"
                      v-model="form.phone"
                      required
                      type="number"
                      placeholder="phone"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ phoneError }}</small>
                  </b-form-group>

                  <!-- Input Password -->
                  <b-form-group
                    id="input-group-password"
                    label="Password:"
                    label-for="input-password"
                  >
                    <b-form-input
                      id="input-password"
                      v-model="form.password"
                      required
                      type="password"
                      placeholder="Password"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ passwordError }}</small>
                  </b-form-group>

                  <!-- Input Address -->
                  <b-form-group
                    id="input-group-address"
                    label="Alamat:"
                    label-for="input-address"
                  >
                    <b-form-textarea
                      id="input-address"
                      v-model="form.address"
                      placeholder="Alamat"
                      rows="4"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group
                    id="input-group-image"
                    label="Foto Profil:"
                    label-for="input-image"
                  >
                    <b-form-file
                      v-model="form.image"
                      placeholder="Upload Gambar"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>

                  <b-button variant="primary">Simpan</b-button>
                  <b-button
                    class="ml-2"
                    variant="default"
                    @click="$router.push({ name: 'nurse-list' })"
                  >
                    Cancel
                  </b-button>
                </b-form>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'Add',

  data() {
    return {
      imgLoaded: false,
      form: {

      }
    }
  },

  components: {
    Card
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    deleteData() {

    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apoteker" },
      { title: "Daftar Apoteker", route: "/human-resource/pharmacist/list" },
      { title: "Edit Apoteker" },
    ])
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>